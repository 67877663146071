// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.highlight {
    text-decoration: underline;
        font-weight: bold;
}

html {
    overflow: hidden
}`, "",{"version":3,"sources":["webpack://./src/css.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;QACtB,iBAAiB;AACzB;;AAEA;IACI;AACJ","sourcesContent":["body {\n    margin: 0;\n    padding: 0;\n}\n\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.highlight {\n    text-decoration: underline;\n        font-weight: bold;\n}\n\nhtml {\n    overflow: hidden\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
