import { SearchContextInterface, UserContextInterface } from '@prev-front/mf-template';
import React, { createContext } from 'react'

export const DocumentosContext = createContext<DocumentosContextInterface>({} as any)

type DocumentosContextInterface = {
    search: SearchContextInterface
    user: UserContextInterface
};


export const DocumentosProvider = ({ children, context }): JSX.Element => {
    return (
        <DocumentosContext.Provider value={context as DocumentosContextInterface}>
            {children}
        </DocumentosContext.Provider>
    )
}
