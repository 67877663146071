/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, Col, Row, Text, notification, usePrevTheme, Button, Spin } from '@prev/ui-kit'
import {
  DownloadOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons'
import React, { useContext, useState } from 'react'
import { format } from "date-fns"
import { LaudoResponseInterface } from '../shared-components/types'
import { DocumentosContext } from '../context/DocumentosContext'

export interface PreviewCardProps {
  laudoProps: LaudoResponseInterface
  downloadLaudo: (id: string) => Promise<any>
  downloading: string | boolean
  navigate?: (path: string) => void
}

export interface RenderCitacaoFullProps {
  texto: string | null | undefined
  highlight: string[]
  color?: string
  bold?: boolean
}

export interface RenderCitacaoProps {
  texto: string | null | undefined
  highlight: string[]
  color?: string
}


export const LaudoPreview = (props: PreviewCardProps): JSX.Element => {
  const { laudoProps, navigate, downloadLaudo, downloading } = props;
  const [fullView, setFullView] = useState(false);
  const { theme, darkMode } = usePrevTheme();
  const { user: { checkPaywall } } = useContext(DocumentosContext)

  const MAX_CHARACTERS = 450; // Número máximo de caracteres para exibição parcial

  const footerColor = darkMode
    ? theme.color.text.default
    : theme.color.text.brand;

  const ocupacoesLaudo = Array.isArray(laudoProps["setores.ocupacoes.ocupacao"]?.raw) ? laudoProps["setores.ocupacoes.ocupacao"]?.raw.slice(0, 2) : [laudoProps["setores.ocupacoes.ocupacao"]?.raw];
  const setores = Array.isArray(laudoProps["setores.setor"]?.raw) ? laudoProps["setores.setor"]?.raw.slice(0, 2) : [laudoProps["setores.setor"]?.raw];
  const cnae = Array.isArray(laudoProps["cnae.descricao"]?.raw) ? laudoProps["cnae.descricao"]?.raw.slice(0, 2) : [laudoProps["cnae.descricao"]?.raw];
  const setoresPlusCnae = [...setores, ...cnae].filter(Boolean).join(" - ");
  // Controlar exibição do texto
  const resumoTexto = laudoProps?.resumo?.raw;
  const displayText = fullView ? resumoTexto : resumoTexto.slice(0, MAX_CHARACTERS);

  return (
    <Card
      data-testid='card-laudo-preview'
      aria-label=''
      id={laudoProps?.id?.raw}
      style={{ width: '100%', marginBottom: "16px" }}
    >
      <Col span={24}>
        <Row
          style={{
            justifyContent: 'space-between',
            marginBottom: '18px',
            alignItems: 'flex-start'
          }}
        >
          <Col style={{ display: "flex", gap: "10px" }}>
            {laudoProps?.tipoDeLaudo?.raw && (
              <Text
                style={{
                  backgroundColor:
                    theme.color.background.accent.blue.subtler.default,
                  color: theme.color.text.accent.blue.default,
                  padding: '0 8px',
                  borderRadius: 128
                }}
              >
                {laudoProps?.tipoDeLaudo?.raw}
              </Text>
            )
            }
            {
              ocupacoesLaudo && ocupacoesLaudo?.map((ocupacao, index) => (
                ocupacao?.length > 0 && (
                  <Text
                    key={`ocupacao-${index}`}
                    style={{
                      backgroundColor:
                        theme.color.background.accent.blue.subtler.default,
                      color: theme.color.text.accent.blue.default,
                      padding: '0 8px',
                      borderRadius: 128
                    }}
                  >
                    {ocupacao}
                  </Text>
                )
              ))
            }
          </Col>
          {laudoProps?.dataDoLaudo?.raw && (
            <Text color={theme.color.text.subtlest} style={{
              backgroundColor:
                theme.color.background.disabled,
              color: theme.color.text.subtle,
              padding: '0 8px',
              borderRadius: 128,
              fontSize: "14px"
            }}>
              Data do laudo:{' '}
              {format(new Date(laudoProps?.dataDoLaudo?.raw), 'dd/MM/yyyy')}{' '}
            </Text>
          )
          }
        </Row>
        <Row style={{ margin: "10px 0" }}>
          <Text emphasis>{setoresPlusCnae}</Text>
        </Row>
        <Row style={{ margin: "10px 0" }}>
          <Text>
            {displayText}
            {!fullView && resumoTexto.length > MAX_CHARACTERS && '...'}
          </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Row style={{ gap: '5px', alignItems: 'center' }}>
            <Text
              color={footerColor}
              onClick={() => setFullView(!fullView)}
              style={{ cursor: 'pointer', color: theme.color.text.info }}
            >
              {(fullView && 'Recolher') || (!fullView && 'Ver mais')}
            </Text>
            {fullView ? (
              <UpOutlined style={{ color: theme.color.text.info }} />
            ) : (
                <DownOutlined style={{ color: theme.color.text.info }} />
            )}
          </Row>
          <Row style={{ gap: '30px' }}>
            {
              !downloading || downloading !== props?.laudoProps?.id?.raw ? (
                <Button
                  icon={<DownloadOutlined />}
                  type='link'
                  style={{ color: footerColor }}
                  decoration={true}
                  onClick={() => {
                    if (navigate) {
                      const ok = checkPaywall('LAUDOS');
                      if (ok) {
                        downloadLaudo(laudoProps?.id?.raw)
                      }
                    }
                  }}
                >
                  Baixar laudo
                </Button>
              ) : (
                <Row style={{ width: "150px" }} justify='center'><Spin data-testid='spin-downloading' /></Row>
              )
            }
          </Row>
        </Row>
      </Col>
    </Card>
  );
};