import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import './css.css'
import { JobsSelect } from './shared-components/JobSelect'
import { PetitionPreview } from './shared-components/Preview'

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: (props: any) => (
    <Root {...props} />
  ),
  // @ts-expect-error pagina de erro //
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null
  }
})

export const { bootstrap, mount, unmount, update } = lifecycles
export { JobsSelect, PetitionPreview } 