/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Col,
  Row,
  Select,
  Text,
  Skeleton,
  Empty,
  usePrevTheme,
  notification,
} from '@prev/ui-kit'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type UseSearchInterface } from '@prev-front/mf-template'
import { useLaudos } from '../hooks/useLaudos'
import { downloadLaudo, LaudoResponseInterface, MetaLaudos, OrdenacaoLaudosInterface } from '../services'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useScrollEnd } from '@prev-front/mf-core'
import { LaudoPreview } from '../shared-components/LaudoPreview'

interface LaudosProps {
  searchContext: UseSearchInterface & { scrollTop: () => void, checkPaywall: () => boolean }
}

export const Laudos = (props: LaudosProps): JSX.Element => {
  const { theme } = usePrevTheme()
  const [laudos, setLaudos] = useState<LaudoResponseInterface[]>([])
  const [metaPage, setMeta] = useState<MetaLaudos | undefined>()
  const [filters, setFilter] = useState<any[]>([])
  const [ordenacao, setOrdenacao] = useState<OrdenacaoLaudosInterface>({
    tipoDeOrdenacao: '',
    ordenaPor: ''
  })
  const { fetchLaudos } = useLaudos()
  const isScrollEnd = useScrollEnd()
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { searchQuery } = props.searchContext
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [downloadFileId, setDownloadingFileId] = useState<string | boolean>(false)

  const downloadWithCaptcha = async (id: string) => {
    try {
      setDownloadingFileId(id)
      const recaptchaToken = await executeRecaptcha?.('laudo_download')
      const data = await downloadLaudo(id, recaptchaToken)
      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `laudo-${id}.pdf` || 'file.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setDownloadingFileId(false)
    } catch (err) {
      setDownloadingFileId(false)
      notification.error({
        message: err.message ?? "Algo deu errado ao realizar o download, tente novamente",
        placement: "topRight"
      })
    }
  }

  const loadlaudos = async (isNextFetch: boolean): Promise<void> => {
    try {
      const pageFilters = [...filters]
      if (isNextFetch && metaPage?.page.current) {
        pageFilters.push({ page: metaPage?.page.current + 1 })
      }
      if (searchQuery) {
        pageFilters.push({ q: searchQuery })
      }
      const { meta, results } = await fetchLaudos(pageFilters, ordenacao)
      setMeta(meta)
      if (isNextFetch) {
        setLaudos((prevState) => [...prevState, ...results])
      } else {
        if (!results || results?.length === 0) {
          setLaudos([])
        } else {
          setLaudos(results)
        } results
        setIsLoading(false)
      }
    } catch (err) {
      notification.error({
        message: err.message ?? 'Algo deu errado ao carregar laudos'
      })
    }
  }

  useEffect(() => {
    if (metaPage && isScrollEnd && (metaPage.page.total_results > laudos.length)) {
      void loadlaudos(isScrollEnd)
    }
  }, [isScrollEnd])

  useEffect(() => {
    if (filters.length) {
      void loadlaudos(false)
    }
  }, [filters, searchQuery])

  useEffect(() => {
    void loadlaudos(false)
  }, [ordenacao.ordenaPor, ordenacao.tipoDeOrdenacao])

  return (
    <Col span={24}>
      <Row justify="space-between" style={{ marginBottom: "40px" }}>
        <Col style={{ paddingBottom: '30px' }}>
          <Text emphasis size="h6">
            Busca de laudos
          </Text>
          <Text color={theme.color.text.subtlest} size="caption">
            {`Mostrando ${laudos?.length || 0} de ${metaPage?.page?.total_results ?? 0
              } laudos`}
          </Text>
        </Col>
        <Row style={{ gap: '20px', alignItems: 'center' }}>
          <Col>
            <Select
              data-testid='select-ordenacao'
              placeholder="Ordenar por"
              style={{
                width: '200px'
              }}
              onChange={(item) => {
                if (['DESC', 'ASC'].includes(item)) {
                  setOrdenacao({
                    ordenaPor: 'dataDoLaudo',
                    tipoDeOrdenacao: item
                  })
                } else {
                  setOrdenacao({
                    ordenaPor: '',
                    tipoDeOrdenacao: ''
                  })
                }
              }}
              options={[
                { label: 'Relevância', value: '' },
                { label: 'Mais recentes', value: 'DESC' },
                { label: 'Mais antigas', value: 'ASC' }
              ]}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%", gap: "10px" }} align='middle'>
          <Text style={{ marginRight: "10px" }}>Filtros:</Text>
          <Col span={3}>
            <Select
              data-testid='select-ano-publicacao'
              onChange={(items: any) => {
                setFilter(
                  items.map((item) => {
                    return { anoDaPublicacao: item }
                  })
                )
              }}
              maxTagCount={1}
              popupMatchSelectWidth={false}
              mode="multiple"
              placeholder="Ano da publicação"
              options={[
                { label: '2022', value: '2022' },
                { label: '2021', value: '2021' },
                { label: '2020', value: '2020' },
                { label: '2019', value: '2019' },
                { label: '2018', value: '2018' },
                { label: '2017', value: '2017' },
                { label: '2016', value: '2016' },
                { label: '2015', value: '2015' },
                { label: '2014', value: '2014' },
                { label: '2013', value: '2013' }
              ]}
            />
          </Col>
        </Row>
      </Row>
      {isLoading && (
        <Row style={{ marginTop: '50px', gap: '15px' }}>
          <Skeleton data-testid='skeleton-loading-laudo' active paragraph={{ rows: 6 }} />
          <Skeleton data-testid='skeleton-loading-laudo' active paragraph={{ rows: 6 }} />
        </Row>
      )}
      {laudos.length === 0 && !isLoading && (
        <Empty
          data-testid='empty-laudo'
          style={{ marginTop: '200px' }}
          description="Nenhum laudo encontrado"
        />
      )}
      {laudos?.length > 0 && (
        <Row>
          {laudos.map((decision) => (
            <LaudoPreview
              downloadLaudo={downloadWithCaptcha}
              searchContext={props.searchContext}
              laudoProps={decision}
              key={decision.id.raw}
              navigate={navigate}
              downloading={downloadFileId}
            />
          ))}
        </Row>
      )}
    </Col>
  )
}
