import React, { useState, useCallback, FocusEventHandler } from 'react'
import {
  Select,
  PrevPlatformProvider,
  Text,
  Form,
  usePrevTheme,
  Label
} from '@prev/ui-kit'
import { debounce } from 'lodash'

export interface JobsSelectProps {
  selected: { codigo: string; titulo: string } | undefined
  setSelected: (any) => void
  onBlur?: FocusEventHandler<HTMLElement>
  showLabel?: boolean
  placeholder?: string
  getJobs: any
  variant?: 'outlined' | 'filled' | 'borderless' | undefined
}

const JobsSelectComponent = (props: JobsSelectProps): JSX.Element => {
  const { selected, setSelected, showLabel, getJobs, ...rest } = props
  const { theme } = usePrevTheme()

  const [selectOptions, setSelectOptions] = useState<
    Array<{ value: string; label: string }>
  >([])
  const [jobsResponse, setJobsResponse] = useState<
    { codigo: string; titulo: string }[]
  >([])

  const debouncedSearch = useCallback(
    debounce(async (searchText: string) => {
      try {
        if (!searchText) return
        const jobsResponse = await getJobs({ q: searchText })
        if (!jobsResponse) return
        const jobs = jobsResponse.map((job) => ({
          value: job.codigo,
          label: job.titulo
        }))
        setSelectOptions(jobs)
        setJobsResponse(jobsResponse)
      } catch (err) {
        console.error(err)
      }
    }, 300),
    []
  )

  const onSearch = (searchText: string): void => {
    if (searchText.length < 2) return
    debouncedSearch(searchText)
  }

  const handleChange = (value: string): void => {
    if (jobsResponse) setSelected(jobsResponse.find((e) => e.codigo === value))
  }

  if (showLabel)
    return (
      <Label>
        Profissão
        <Form.Item
          layout="vertical"
          required={false}
        >
          <Select
            data-testid='search-profissao'
            onSearch={onSearch}
            style={{ height: '40px' }}
            onChange={handleChange}
            placeholder="Pesquisar profissão"
            value={selected?.titulo}
            notFoundContent={<Text>Digite para pesquisar</Text>}
            filterOption={false}
            showSearch={true}
            options={selectOptions}
            {...rest}
          />
        </Form.Item>
      </Label>
    )

  return (
    <Select
      data-testid='select-profissao'
      onSearch={onSearch}
      style={{ height: '40px', width: '100%' }}
      onChange={handleChange}
      placeholder="Pesquisar profissão"
      value={selected?.titulo}
      notFoundContent={<Text>Digite para pesquisar</Text>}
      filterOption={false}
      showSearch={true}
      options={selectOptions}
      {...rest}
    />
  )
}

export const JobsSelect = (props: JobsSelectProps): JSX.Element => {
  return (
    <PrevPlatformProvider>
      <JobsSelectComponent {...props} />
    </PrevPlatformProvider>
  )
}
