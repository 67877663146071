import React from 'react'
import { Col, Switch, Tag, Text, usePrevTheme } from '@prev/ui-kit'
import { type CasosInterface } from '../../../services/types'
import {
  TEXT_CASES_TYPES,
  TIPOS_BENEFICIOS_CALCULO
} from '@prev-front/shared-api'

import {
  familiar,
  historicoOpcoesHomem,
  historicoOpcoesMulher
} from '../../../utils/respostasFicha'

interface ClientCaseProps {
  data?: CasosInterface
}

export const ClientCaseSheet = (props: ClientCaseProps): JSX.Element => {
  const { theme, darkMode } = usePrevTheme()

  return (
    <Col style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Text>Detalhes do caso</Text>
      <Text emphasis color={theme.color.text.subtle}>
        Tipo do caso
      </Text>
      <Tag
        data-testid='tag-tipo-caso'
        style={{
          borderRadius: theme.borderRadius.round,
          color: darkMode ? "white" : theme.color.text.accent.blue.default,
          backgroundColor: darkMode ? theme.color.background.accent.blue.bolder.default : theme.color.background.accent.blue.subtle.default
        }}
      >
        {
          TEXT_CASES_TYPES.find(
            (casoAjuda) =>
              casoAjuda.tipo === props.data?.questionario?.tipoDeAjuda
          )?.desc
        }
      </Tag>
      {props.data?.questionario.grauDeParentesco && (
        <>
          <Text emphasis color={theme.color.text.subtle}>
            Grau de parentesco
          </Text>
          <Tag
            data-testid='tag-grau-parentesco'
            style={{
              borderRadius: theme.borderRadius.round,
              color: darkMode ? "white" : theme.color.text.accent.blue.default,
              backgroundColor: darkMode ? theme.color.background.accent.blue.bolder.default : theme.color.background.accent.blue.subtle.default
            }}
          >
            {
              familiar.find(
                (e) => e.tipo === props.data?.questionario?.grauDeParentesco
              )?.desc
            }
          </Tag>
        </>
      )}
      {props.data?.questionario.historicoDoSegurado && (
        <>
          <Text emphasis color={theme.color.text.subtle}>
            Histórico
          </Text>
          <Tag
            data-testid='tag-historico'
            style={{
              borderRadius: theme.borderRadius.round,
              color: darkMode ? "white" : theme.color.text.accent.blue.default,
              backgroundColor: darkMode ? theme.color.background.accent.blue.bolder.default : theme.color.background.accent.blue.subtle.default
            }}
          >
            {props.data?.questionario.sexo === 'MASCULINO'
              ? historicoOpcoesHomem.find(
                (e) =>
                  e.tipo === props.data?.questionario?.historicoDoSegurado
              )?.desc
              : historicoOpcoesMulher.find(
                (e) =>
                  e.tipo === props.data?.questionario?.historicoDoSegurado
              )?.desc}
          </Tag>
        </>
      )}
      {
        props.data?.questionario?.beneficioAntigo && (
          <Tag
            data-testid='tag-beneficio-antigo'
            style={{
              marginBottom: "15px",
              borderRadius: theme.borderRadius.round,
              color: darkMode ? "white" : theme.color.text.accent.blue.default,
              backgroundColor: darkMode ? theme.color.background.accent.blue.bolder.default : theme.color.background.accent.blue.subtle.default
            }}
          >
            {
              TIPOS_BENEFICIOS_CALCULO.find(
                (e) => e.value === props.data?.questionario?.beneficioAntigo
              )?.label
            }
          </Tag>
        )
      }
      <Text emphasis color={theme.color.text.subtle}>
        Já realizou peido e concessão do benefício
      </Text>
      <Switch
        data-testid='switch-pedido-concessao'
        style={{ width: '50px' }}
        checked={props.data?.questionario?.pedidoDeConcessao}
        checkedChildren='Sim'
        unCheckedChildren="Não"
      />
      <Text emphasis color={theme.color.text.subtle}>
        Já realizou pedido para revisar o benefício no INSS
      </Text>
      <Switch
        data-testid='switch-pedido-revisao'
        style={{ width: '50px' }}
        checked={props.data?.questionario?.pedidoDeRevisao}
        checkedChildren='Sim'
        unCheckedChildren="Não"
      />
      <Text emphasis color={theme.color.text.subtle}>
        Já contribuiu para Previdência Social?
      </Text>
      <Switch
        data-testid='switch-previdencia-social'
        style={{ width: '50px' }}
        checked={props.data?.questionario?.jaContribuiuParaPrevidenciaSocial}
        checkedChildren='Sim'
        unCheckedChildren="Não"
      />
      <Text
        emphasis
        color={theme.color.text.subtle}
        style={{ marginTop: '20px', marginBottom: '10px' }}
      >
        Detalhes do caso pelo cliente
      </Text>
      <Text>{props.data?.descricao}</Text>
    </Col>
  )
}
