import {
  Label,
  Radio,
  Message,
  Col,
  FieldInput,
  Form,
  Row,
  Text,
  Select,
  Card,
  Divider,
  type FormInstance,
  usePrevTheme,
  DatePicker,
  notification,
} from '@prev/ui-kit'
import React, { useContext, useEffect, useState } from 'react'
import {
  type NewClient,
  createClient,
  type Profissao,
  getEnderecoByCep,
  getJobs
} from '../../services'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ApiError } from '@prev-front/shared-api'
import { ajustHifen, cleanObject, escolaridade, estados } from '../../utils'
import { AnimationHandlerContext } from '../../context/AnimationHandlerContext'
import moment from 'moment'
import { useWidth, LocalLoading } from '@prev-front/mf-core'
import { JobsSelect } from '../../shared-components/JobSelect'

interface Props {
  form: FormInstance<NewClient>
}

export const CreateClientForm = (props: Props): JSX.Element => {
  const { form } = props
  const [selectedJob, setSelectedJob] = useState<Profissao>()
  const { theme, darkMode } = usePrevTheme()
  const { createClient: createClientModal } = useContext(
    AnimationHandlerContext
  )
  const today = moment(Date.now()).format('YYYY-MM-DD')
  const [mainPhoneMask, setMainPhoneMask] = useState('(99) 99999-9999')
  const [secondaryPhoneMask, setSecondaryPhoneMask] =
    useState('(99) 99999-9999')
  const [cellPhoneMask, setCellPhoneMask] = useState('(99) 99999-9999')
  const [loading, setLoading] = useState(false)
  const width = useWidth()

  useEffect(() => {
    handleMasks()
  }, [])

  const fetchAddress = async (cep: string): Promise<void> => {
    try {
      const onlyNumbersCep = cep.replace(/\D/g, '')
      const endereco = await getEnderecoByCep(onlyNumbersCep)
      endereco.numero && delete endereco.numero
      props.form.setFieldsValue({ endereco })
    } catch (err) { }
  }

  const handleSubmit = async (): Promise<void> => {
    try {
      const dataValues = form.getFieldsValue()
      const withJobs = {
        ...dataValues,
        profissoes: [selectedJob]
      }
      const data = cleanObject(withJobs)
      setLoading(true)
      await createClient(data)
      form.resetFields()
      notification.success({
        message: 'Cliente cadastrado com sucesso!',
        placement: 'bottomRight'
      })
      setLoading(false)
      createClientModal.setIsVisibleCreate(false)
    } catch (err) {
      setLoading(false)
      notification.error({
        message: err.message,
        placement: 'topRight'
      })
      if (!(err instanceof ApiError))
        void Message.error('Algo deu errado, tente novamente mais tarde')
    }
  }

  const handleMasks = (): void => {
    setMainPhoneMask('(99) 99999-9999')
    setSecondaryPhoneMask('(99) 99999-9999')
    setCellPhoneMask('(99) 99999-9999')
  }

  if (loading)
    return (
      <LocalLoading
        display={loading}
        style={{ height: '100vh', overflow: 'hidden' }}
      />
    )

  return (
    <Row
      style={{
        justifyContent: 'center',
        padding: `0px 20px 20px ${width <= 1200 ? '20px' : '130px'}`
      }}
      gutter={24}
    >
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={18}
        xxl={18}
        style={{ width: '100%', position: 'relative' }}
      >
        <Col span={24} style={{ marginBottom: '22px' }}>
          <Text type="paragraph" style={{ marginBottom: '10px' }}>
            Dados pessoais do cliente
          </Text>
          <Row align="middle" style={{ gap: '5px' }}>
            <InfoCircleOutlined style={{ color: theme.color.icon.danger }} />
            <Text size="body">Campos de preenchimento obrigatório</Text>
          </Row>
        </Col>
        <Form
          data-testid='form-novo-cliente'
          name="newClientForm"
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
          scrollToFirstError={{
            behavior: 'smooth',
            block: 'center'
          }}
          onFinish={handleSubmit}
          form={form}
        >
          <Row style={{ justifyContent: 'space-between' }} gutter={12}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Label>
                Nome completo
                <Form.Item
                  name="nome"
                  rules={[
                    {
                      required: true,
                      message: 'Insira o nome do cliente'
                    }
                  ]}
                >
                  <FieldInput
                    data-testid='input-nome-completo'
                    required
                    name="nome"
                    placeholder="Digite o nome do cliente"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                CPF
                <Form.Item
                  name="cpf"
                  validateDebounce={500}
                  rules={[
                    {
                      required: true,
                      message: 'Insira o CPF do cliente'
                    },
                    {
                      pattern: /^(?:\d{3}\.?\d{3}\.?\d{3}-?\d{2}|\d{11})$/,
                      message: 'CPF inválido'
                    }
                  ]}
                >
                  <FieldInput.CPF
                    data-testid='input-cpf'
                    name='input-cpf'
                    required
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
              <Form.Item
                name="sexo"
                required
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório'
                  }
                ]}
              >
                <Row style={{ marginBottom: "13px" }}>
                  <Text color={theme.color.text.subtle} size="body">
                    Sexo{' '}
                    <InfoCircleOutlined
                      style={{
                        color: darkMode
                          ? theme.color.text.subtle
                          : theme.color.icon.brand
                      }}
                    />
                  </Text>
                </Row>
                <Row>
                  <Form.Item name="sexo">
                    <Radio.Group>
                      <Radio data-testid='radio-masculino' value="MASCULINO">Masculino</Radio>
                      <Radio data-testid='radio-feminino' value="FEMININO">Feminino</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: 'space-between', paddingTop: '20px' }}
            gutter={12}
          >
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                Nascimento
                <Form.Item
                  name="dataDeNascimento"
                  rules={[
                    {
                      required: true,
                      message: 'A data de nascimento é obrigatória'
                    }
                  ]}
                  style={{ margin: 0, padding: 0 }}
                >
                  <FieldInput.Date
                    name='input-nascimento'
                    data-testid='input-nascimento'
                    required
                    max={today}
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Label>
                Estado civil
                <Form.Item
                  name="estadoCivil"
                  style={{ margin: 0, padding: 0 }}
                  initialValue={'SOLTEIRO'}
                >
                  <Select
                    data-testid='select-estado-civil'
                    options={[
                      {
                        label: 'Solteiro',
                        value: 'SOLTEIRO'
                      },
                      {
                        label: 'Casado',
                        value: 'CASADO'
                      },
                      {
                        label: 'Viuvo',
                        value: 'VIUVO'
                      },
                      {
                        label: 'Divorciado',
                        value: 'DIVORCIADO'
                      },
                      {
                        label: 'Separado',
                        value: 'SEPARADO'
                      }
                    ]}
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Form.Item>
                <JobsSelect
                  showLabel={true}
                  selected={selectedJob}
                  setSelected={setSelectedJob}
                  getJobs={getJobs}
                />
              </Form.Item>
            </Col>
          </Row>
          <Text style={{ margin: '20px 0' }}>Dados de contato do cliente</Text>
          <Row style={{ justifyContent: 'space-between' }} gutter={12}>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Label>
                Email
                <Form.Item
                  style={{ margin: 0, padding: 0, width: '100%' }}
                  name="email"
                  validateDebounce={500}
                  rules={[
                    {
                      type: 'email',
                      message: 'Email inválido'
                    }
                  ]}
                >
                  <FieldInput
                    data-testid='input-email'
                    placeholder="Digite o email do cliente"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Label>
                Celular
                <Form.Item
                  validateDebounce={500}
                  name="celular"
                  rules={[
                    {
                      message: 'Número inválido',
                      min: 14
                    }
                  ]}
                >
                  <FieldInput.Phone
                    data-testid='input-telefone'
                    name='input-telefone'
                    style={{ width: '100%' }}
                    placeholder="Digite seu telefone"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Label>
                Telefone principal
                <Form.Item
                  validateDebounce={500}
                  name="telefonePrincipal"
                  rules={[
                    {
                      message: 'Número inválido',
                      min: 14
                    }
                  ]}
                >
                  <FieldInput.Phone
                    data-testid='input-telefone-principal'
                    name='input-telefone-principal'
                    style={{ width: '100%' }}
                    placeholder="Digite seu telefone"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Label>
                Telefone secundário
                <Form.Item
                  validateDebounce={500}
                  name="telefoneSecundario"
                  rules={[
                    {
                      message: 'Número inválido',
                      min: 14
                    }
                  ]}
                >
                  <FieldInput.Phone
                    data-testid='telefone-secundario'
                    name='telefone-secundario'
                    style={{ width: '100%' }}
                    placeholder="Digite o telefone"
                  />
                </Form.Item>
              </Label>
            </Col>
          </Row>
          <Text style={{ margin: '20px 0' }}>Endereço do cliente</Text>
          <Row style={{ justifyContent: 'space-between' }} gutter={12}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Label>
                CEP
                <Form.Item name={['endereco', 'cep']}>
                  <FieldInput.MASK mask='00000-000' onBlur={(event) => {
                    if (event?.target?.value.length >= 9) {
                      void fetchAddress(event.target.value)
                    }
                  }} placeholder='00000-000' />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                Rua/avenida
                <Form.Item name={['endereco', 'endereco']}>
                  <FieldInput
                    data-testid='input-rua-avenida'
                    placeholder="Digite o nome da rua ou avenida"
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                Número
                <Form.Item name={['endereco', 'numero']}>
                  <FieldInput data-testid='input-numero-endereco' placeholder="Digite o número" />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                Complemento
                <Form.Item name={['endereco', 'complemento']}>
                  <FieldInput data-testid='input-complemento-endereco' placeholder="apt/sala/bloco" />
                </Form.Item>
              </Label>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: 'space-between', marginTop: '20px' }}
            gutter={12}
          >
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Label>
                Bairro
                <Form.Item name={['endereco', 'bairro']}>
                  <FieldInput data-testid='input-bairro' placeholder="Digite o bairro" />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Label>
                Cidade
                <Form.Item name={['endereco', 'cidade']}>
                  <FieldInput data-testid='input-cidade' placeholder="Digite a cidade" />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Label>
                Estado
                <Form.Item name={['endereco', 'uf']}>
                  <Select
                    data-testid='select-estado'
                    options={estados}
                    placeholder="Selecione seu estado"
                  />
                </Form.Item>
              </Label>
            </Col>
          </Row>
          <Text style={{ margin: '20px 0' }}>Outros dados e documentos</Text>
          <Row style={{ justifyContent: 'space-between' }} gutter={12}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                Escolaridade
                <Form.Item
                  name="escolaridade"
                  initialValue={escolaridade[0].value}
                >
                  <Select
                    data-testid='select-escolaridade'
                    placeholder="Selecione a escolaridade"
                    dropdownStyle={{ zIndex: 9999999 }}
                    options={escolaridade.map((e) => ({
                      label: e.desc,
                      value: e.value
                    }))}
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                RG
                <Form.Item
                  name="rg"
                  rules={[
                    { required: false, min: 8, message: 'Formato inválido' }
                  ]}
                >
                  <FieldInput
                    data-testid='input-rg'
                    placeholder="00.000.000-0"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                CNH
                <Form.Item name="cnh">
                  <FieldInput data-testid='input-cnh' placeholder="000000000000" />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                CTPS
                <Form.Item
                  name="ctps"
                  rules={[
                    { min: 16, required: false, message: 'Formato inválido' }
                  ]}
                >
                  <FieldInput.MASK
                    placeholder="000.000000.00-00"
                    mask="000.000000.00-00"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Label>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: 'space-between', marginTop: '40px' }}
            gutter={12}
          >
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Label>
                NIT
                <Form.Item
                  name="nit"
                  rules={[
                    { min: 14, required: false, message: 'Formato inválido' }
                  ]}
                >
                  <FieldInput.MASK
                    data-testid='input-nit'
                    mask="000.00000.00-0"
                    placeholder="000.00000.00-0"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Label>
                NIT Secundário
                <Form.Item
                  name="nitSecundario"
                  rules={[
                    { min: 14, required: false, message: 'Formato inválido' }
                  ]}
                >
                  <FieldInput.MASK
                    mask="000.00000.00-0"
                    placeholder="000.00000.00-0"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Label>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Label>
                Data de óbito
                <Form.Item name="dataDeObito">
                  <DatePicker data-testid='datepicker-data-obito' />
                </Form.Item>
              </Label>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={0}
        xl={6}
        xxl={6}
        style={{ height: '100%', width: '100%' }}
      >
        <Card data-testid='card-dicas-cadastro' aria-label='' style={{ width: '100%', height: '100%' }}>
          <Text type="paragraph">
            Por que é importante o cadastro completo do meu cliente?
          </Text>
          <Divider />
          <Text type="paragraph">
            Prev é a plataforma previdenciária mais moderna e mais segura do
            mercado!
          </Text>
          <Text>
            Aqui seus dados são protegidos, além disso não usaremos seu email
            para enviar promoções, usaremos somente para envio dos emails
            transacionais e de atualizações da plataforma.
          </Text>
        </Card>
      </Col>
    </Row>
  )
}
