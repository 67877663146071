import {
  Card,
  Col,
  Empty,
  PrevPlatformProvider,
  Row,
  Skeleton,
  Tag,
  Text,
  usePrevTheme,
} from '@prev/ui-kit'
import styled from 'styled-components'
import { BENEFICIOS } from "../constants"
import { type UseSearchInterface } from "@prev-front/mf-template";
import React from 'react';
const StyledCard = styled(Card)`
`

export interface PetitionPreviewInterface {
  tags?: string[]
  tipo: string
  resumo: string
  dataDeCriacao: string
  id: string
  titulo: string
  tiposDeBeneficio: [string]
  subtipo: [string]
  dataDaUltimaAtualizacao: string
}

export interface PetitionProps {
  petitions: PetitionPreviewInterface[]
  editorRedirect: (id: string) => void
  loading: boolean
  searchContext: UseSearchInterface & { scrollTop: () => void, checkPaywall: () => boolean }
}

export const PetitionPreview = (props: PetitionProps): JSX.Element => {
  const { petitions, loading, searchContext } = props
  const { theme, darkMode } = usePrevTheme()
  const { checkPaywall } = searchContext

  const formatDateTime = (date): string => {
    const dateString = new Date(date).toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    const timeString = new Date(date).toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit'
    })

    return `${dateString} - ${timeString}`
  }

  const renderSkeleton = (): JSX.Element | null => {
    if (loading) {
      return (
        <Row>
          <Card
            aria-label=''
            data-testid='card-peticao-loading'
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              boxShadow: "0px 1px 2px 1px rgba(39, 39, 46, 0.25), 0px 0px 1px 0px rgba(39, 39, 46, 0.31)"
            }}
          >
            <Skeleton data-testid='skeleton-loading-peticao' loading={loading} active />
          </Card>
          <Card
            data-testid='card-peticao-loading'
            aria-label=''
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              boxShadow: "0px 1px 2px 1px rgba(39, 39, 46, 0.25), 0px 0px 1px 0px rgba(39, 39, 46, 0.31)"
            }}
          >
            <Skeleton data-testid='skeleton-loading-peticao' loading={loading} active />
          </Card>
          <Card
            data-testid='card-peticao-loaing'
            aria-label=''
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              boxShadow: "0px 1px 2px 1px rgba(39, 39, 46, 0.25), 0px 0px 1px 0px rgba(39, 39, 46, 0.31)"
            }}
          >
            <Skeleton data-testid='skeleton-loading-peticao' loading={loading} active />
          </Card>
        </Row>
      )
    }
    return null
  }

  return (
    <PrevPlatformProvider>
      <Row style={{ gap: '15px' }}>
        {petitions?.length >= 1 &&
          petitions.map((petition) => (
            <StyledCard
              datatype='card-peticao-preview'
              aria-label=''
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                boxShadow: "0px 1px 2px 1px rgba(39, 39, 46, 0.25), 0px 0px 1px 0px rgba(39, 39, 46, 0.31)"
              }}
              data-testid={`card-resumo-peticao-${petition.titulo}`}
              key={petition.resumo}
            >
              <Row justify="space-between">
                <Row style={{ gap: '20px' }}>
                  {petition.tiposDeBeneficio?.map((beneficio) => (
                    <Tag
                      data-testid={`peticao-resumo-tag-${beneficio}`}
                      key={`tag-${petition.id}`}
                      style={{
                        backgroundColor: theme.color.background.accent.blue.subtler.default,
                        fontWeight: 400,
                        color: theme.color.text.accent.blue.default,
                        padding: '0 8px',
                        borderRadius: '128px',
                        height: "20px",
                        fontSize: "12px",
                        border: "none"
                      }}
                    >
                      {
                        BENEFICIOS.find(
                          (beneficioConst) => beneficio === beneficioConst.value
                        )?.label
                      }
                    </Tag>
                  ))}
                </Row>
                <Col>
                  <Row style={{ gap: "5px" }}>
                  <Tag
                      data-testid={`peticao-resumo-tag-data-publicacao`}
                      key={`tag-${petition.id}`}
                      style={{
                        backgroundColor: theme.color.background.disabled,
                        fontWeight: 400,
                        color: theme.color.text.subtle,
                        padding: '0 8px',
                        borderRadius: '128px',
                        height: "20px",
                        fontSize: "14px",
                        border: "none"
                      }}
                    >
                      06/06/2024
                    </Tag>
                  </Row>
                </Col>
              </Row>
              <Text data-testid='peticao-resumo-texto' style={{ fontSize: "12px", color: theme.color.text.default, marginTop: "16px" }}>{petition.resumo}</Text>
            </StyledCard>
          ))}
      </Row>
      {renderSkeleton()}
      {!loading && (!petitions || petitions.length === 0) && (
        <Empty
          data-testid='empty-peticoes'
          description="Não foram encontradas petições para este filtro"
          style={{ marginTop: '100px' }}
        />
      )}
    </PrevPlatformProvider>
  )
}
