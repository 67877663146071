import React, { useState, useCallback, FocusEventHandler } from 'react';
import {
  Select,
  PrevPlatformProvider,
  Text,
  Form,
  usePrevTheme,
  Label,
} from '@prev/ui-kit';
import { debounce } from 'lodash';
import styled from 'styled-components';

export interface JobsSelectProps {
  selected:
  | { codigo: string; titulo: string }
  | Array<{ codigo: string; titulo: string }>
  | undefined;
  setSelected: (value: any) => void;
  onBlur?: FocusEventHandler<HTMLElement>;
  showLabel?: boolean;
  placeholder?: string;
  getJobs: any;
  variant?: 'outlined' | 'filled' | 'borderless' | undefined;
  mode?: 'multiple' | 'tags' | undefined;
}

const StyledSelect = styled(Select)`
  .ant-select-selection-overflow {
    min-height: 40px !important;
  }
`;

const JobsSelectComponent = (props: JobsSelectProps): JSX.Element => {
  const { selected, setSelected, showLabel, getJobs, mode, ...rest } = props;

  const [selectOptions, setSelectOptions] = useState<
    Array<{ value: string; label: string }>
    >([]);
  const [jobsResponse, setJobsResponse] = useState<
    { codigo: string; titulo: string }[]
    >([]);

  const debouncedSearch = useCallback(
    debounce(async (searchText: string) => {
      try {
        if (!searchText) return;
        const jobsResponse = await getJobs({ q: searchText });
        if (!jobsResponse) return;
        const jobs = jobsResponse.map((job) => ({
          value: job.codigo,
          label: job.titulo,
        }));
        setSelectOptions(jobs);
        setJobsResponse(jobsResponse);
      } catch (err) {
        console.error(err);
      }
    }, 300),
    []
  );

  const onSearch = (searchText: string): void => {
    if (searchText.length < 2) return;
    debouncedSearch(searchText);
  };

  const handleChange = (value: string | string[]): void => {
    if (Array.isArray(value)) {
      // Para mode="multiple"
      const selectedJobs = value
        .map((codigo) => jobsResponse.find((job) => job.codigo === codigo))
        .filter(Boolean); // Remove undefined caso algum código não seja encontrado
      setSelected(selectedJobs);
    } else {
      // Para modo padrão (único)
      const selectedJob = jobsResponse.find((job) => job.codigo === value);
      setSelected(selectedJob);
    }
  };

  // Ajusta o valor do Select com base no modo
  const selectValue =
    mode === 'multiple'
      ? (selected as Array<{ codigo: string; titulo: string }>)?.map(
        (item) => item.codigo
      ) // Array de códigos para múltiplos itens
      : (selected as { codigo: string; titulo: string })?.codigo; // Código único para modo padrão

  if (showLabel)
    return (
      <Label>
        Profissão
        <Form.Item layout="vertical" required={false}>
          <StyledSelect
            data-testid="search-profissao"
            onSearch={onSearch}
            size="middle"
            onChange={handleChange}
            placeholder="Pesquisar profissão"
            value={selectValue}
            notFoundContent={<Text>Digite para pesquisar</Text>}
            filterOption={false}
            showSearch={true}
            options={selectOptions}
            mode={mode} // Define o modo (single ou multiple)
            {...rest}
          />
        </Form.Item>
      </Label>
    );

  return (
    <Select
      data-testid="select-profissao"
      onSearch={onSearch}
      style={{ height: '40px', width: '100%' }}
      onChange={handleChange}
      placeholder="Pesquisar profissão"
      value={selectValue}
      notFoundContent={<Text>Digite para pesquisar</Text>}
      filterOption={false}
      showSearch={true}
      options={selectOptions}
      mode={mode} // Define o modo (single ou multiple)
      {...rest}
    />
  );
};

export const JobsSelect = (props: JobsSelectProps): JSX.Element => {
  return (
    <PrevPlatformProvider>
      <JobsSelectComponent {...props} />
    </PrevPlatformProvider>
  );
};
