export const TIPOS_PETICOES = [
  {
    label: 'Acordo - Contraproposta',
    value: 'ACORDO_CONTRAPROPOSTA'
  },
  {
    label: 'Agravo de Instrumento',
    value: 'AGRAVO_DE_INSTRUMENTO'
  },
  {
    label: 'Agravo Interno',
    value: 'AGRAVO_INTERNO'
  },
  {
    label: 'Agravos',
    value: 'AGRAVOS'
  },
  {
    label: 'Análises de Aposentadoria',
    value: 'ANALISES_DE_APOSENTADORIA'
  },
  {
    label: 'Antecipação de Tutela',
    value: 'ANTECIPACAO_DE_TUTELA'
  },
  {
    label: 'Contestações',
    value: 'CONTESTACOES'
  },
  {
    label: 'Contrarrazões',
    value: 'CONTRARRAZOES'
  },
  {
    label: 'Contrarrazões Administrativas',
    value: 'CONTRARRAZOES_ADMINISTRATIVAS'
  },
  {
    label: 'Cumprimento de sentença',
    value: 'CUMPRIMENTO_DE_SENTENCA'
  },
  {
    label: 'Declarações',
    value: 'DECLARACOES'
  },
  {
    label: 'Defesas Administrativas',
    value: 'DEFESAS_ADMINISTRATIVAS'
  },
  {
    label: 'Embargos',
    value: 'EMBARGOS'
  },
  {
    label: 'Embargos de Declaração',
    value: 'EMBARGOS_DE_DECLARACAO'
  },
  {
    label: 'Embargos Infrigentes',
    value: 'EMBARGOS_INFRIGENTES'
  },
  {
    label: 'Incidente de Uniformização',
    value: 'INCIDENTE_DE_UNIFORMIZACAO'
  },
  {
    label: 'Manifestações',
    value: 'MANIFESTACOES'
  },
  {
    label: 'Petições',
    value: 'PETICOES'
  },
  {
    label: 'Petições Administrativas',
    value: 'PETICOES_ADMINISTRATIVAS'
  },
  {
    label: 'Petições Iniciais',
    value: 'PETICOES_INICIAIS'
  },
  {
    label: 'Propostas de Acordo',
    value: 'PROPOSTA_DE_ACORDO'
  },
  {
    label: 'Recurso de Apelação',
    value: 'RECURSO_DE_APELACAO'
  },
  {
    label: 'Recurso Especial',
    value: 'RECURSO_ESPECIAL'
  },
  {
    label: 'Recurso Extraordinário',
    value: 'RECURSO_EXTRAORDINARIO'
  },
  {
    label: 'Recurso Inominado',
    value: 'RECURSO_INOMINADO'
  },
  {
    label: 'Recursos',
    value: 'RECURSOS'
  },
  {
    label: 'Recursos Administrativos',
    value: 'RECURSO_ADMINISTRATIVO'
  },
  {
    label: 'Réplicas',
    value: 'REPLICAS'
  },
  {
    label: 'Requerimento Administrativo',
    value: 'REQUERIMENTO_ADMINISTRATIVO'
  }
]

export const BENEFICIOS = [
  { label: "Aposentadoria por tempo de contribuição", value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO" },
  { label: "Aposentadoria por idade", value: "APOSENTADORIA_POR_IDADE" },
  { label: "Aposentadoria especial", value: "APOSENTADORIA_ESPECIAL" },
  { label: "Auxílio-doença", value: "AUXILIO_DOENCA" },
  { label: "Auxílio-acidente", value: "AUXILIO_ACIDENTE" },
  { label: "Pensão por morte", value: "PENSAO_POR_MORTE" },
  { label: "Auxílio-reclusão", value: "AUXILIO_RECLUSAO" },
  { label: "Salário maternidade", value: "SALARIO_MATERNIDADE" },
  { label: "Benefício assistencial (LOAS)", value: "BENEFICIO_ASSISTENCIAL" },
  { label: "Aposentadoria por invalidez", value: "APOSENTADORIA_POR_INVALIDEZ" },
  { label: "Aposentadoria por tempo de contribuição pontos", value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS" },
  { label: "Aposentadoria por incapacidade permanente", value: "APOSENTADORIA_POR_INCAPACIDADE_PERMANENTE" },
  { label: "Auxílio por incapacidade temporária acidentário", value: "AUXILIO_INCAPACIDADE_TEMPORARIA_ACIDENTE" },
  { label: "Auxílio por incapacidade temporária", value: "AUXILIO_INCAPACIDADE_TEMPORARIA" },
  { label: "Aposentadoria por Tempo de Contribuição - Professor", value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PROFESSOR" },
  { label: "Aposentadoria por Tempo de Contribuição - Professor (Pontos)", value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS_PROFESSOR" },
  { label: "Aposentadoria por idade (Rural)", value: "APOSENTADORIA_POR_IDADE_RURAL" },
  { label: "Aposentadoria Rural", value: "APOSENTADORIA_RURAL" },
  { label: "Aposentadoria por Idade da Pessoa com Deficiência", value: "APOSENTADORIA_POR_IDADE_DEFICIENCIA" },
  { label: "Aposentadoria por Tempo de Contribuição da Pessoa com Deficiência", value: "APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_DEFICIENCIA" },
  { label: "Assistencial", value: "ASSISTENCIAL" },
  { label: "Incapacidade", value: "INCAPACIDADE" },
  { label: "Benefício assistencial (LOAS)", value: "BENEFICIO_ASSISTENCIAL_IDOSO" },
  {
    label: "Aposentadoria por incapacidade",
    value: "APOSENTADORIA_POR_INCAPACIDADE",
  },
];


export const PERIODOS = [
  {
    label: 'Pós-reforma',
    value: 'REFORMA'
  },
  {
    label: 'Pré-reforma',
    value: 'PRE_REFORMA'
  },
  {
    label: 'Regra de transição',
    value: 'TRANSICAO'
  }
]

export const SUBTIPOS = [
  {
    label: 'Idade mínima progressiva',
    value: 'IDADE_MINIMA_PROGRESSIVA'
  },
  {
    label: 'Idade mínima progressiva Professor',
    value: 'IDADE_MINIMA_PROGRESSIVA_PROFESSOR'
  },
  {
    label: 'Normal',
    value: 'NORMAL'
  },
  {
    label: 'Pedágio 100%',
    value: 'PEDAGIO_100'
  },
  {
    label: 'Pedágio 100% Professor',
    value: 'PEDAGIO_100_PROFESSOR'
  },
  {
    label: 'Pedágio 50%',
    value: 'PEDAGIO_50'
  },
  {
    label: 'Pessoa com deficiência',
    value: 'DEFICIENCIA'
  },
  {
    label: 'Pontos',
    value: 'NORMAL_PONTOS'
  },
  {
    label: 'Pontos Professor',
    value: 'PROFESSOR_PONTOS'
  },
  {
    label: 'Professor',
    value: 'PROFESSOR'
  },
  {
    label: 'Rural',
    value: 'RURAL'
  }
]
