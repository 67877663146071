import { PrevPlatformProvider } from '@prev/ui-kit'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import React, { Suspense } from 'react'
import { Laudos } from './pages/Laudos'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { DocumentosProvider } from './context/DocumentosContext'

const Petitions = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-petitions" */ './pages/Petitions'
    ).then(module => ({ default: module.Petitions }))
)

const EditorView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-editorview" */ './pages/EditorView'
    ).then(module => ({ default: module.EditorView }))
)

const Jurisprudencia = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-jurisprudencia" */ './pages/Jurisprudencia'
    ).then(module => ({ default: module.Jurisprudencia }))
)

const Casos = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-casos" */ './pages/Casos'
    ).then(module => ({ default: module.Casos }))
)

const ClientEditorView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-clientEditorView" */ './pages/ClientEditorView'
    ).then(module => ({ default: module.ClientEditorView }))
)

export interface RootProps {
  context: any
}

export default function Root(props: RootProps): JSX.Element {
  const darkMode = localStorage.getItem('darkMode')
  if (darkMode === 'true') {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#27272E'
  } else {
    document.getElementsByTagName('body')[0].style.backgroundColor = 'white'
  }

  return (
    <DocumentosProvider context={{ search: props.context?.searchProps, user: props.context?.userProps }}>
      <PrevPlatformProvider context={{ theme: props.context?.themeProps?.theme, darkMode: props.context?.themeProps?.darkMode, toggleDarkMode: props.context?.themeProps.toggleDarkMode }}>
        <Suspense>
          <BrowserRouter>
            <Routes>
              <Route path="/peticoes" Component={() =>
                <Petitions />
              }
              />
              <Route path="/peticoes/editor/:id" Component={() => <PrevPlatformProvider><EditorView /></PrevPlatformProvider>} />
              <Route path="/peticoes/editor/:id/:cpf" Component={() => <PrevPlatformProvider><ClientEditorView /></PrevPlatformProvider>} />
              <Route path="/jurisprudencia" Component={() => <Jurisprudencia />}
              />
              <Route path="/casos" Component={() =>
                <Casos />
              }
              />
              <Route path="/laudos" Component={() =>
                <GoogleReCaptchaProvider reCaptchaKey="6Lf0VccUAAAAANdGE1BkdpnSEnbb07RmUKLIP3KK" language="pt-BR">
                  <Laudos />
                </GoogleReCaptchaProvider>
              }
              />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </PrevPlatformProvider>
    </DocumentosProvider>
  )
}
