import {
  Card,
  Col,
  Empty,
  PrevPlatformProvider,
  Row,
  Skeleton,
  Tag,
  Text,
  usePrevTheme,
  Button
} from '@prev/ui-kit'
import React, { useContext, useState } from 'react'
import { DownOutlined, EditOutlined, EyeOutlined, StarFilled, UpOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { BENEFICIOS, SUBTIPOS } from '../constants'
import { DocumentosContext } from '../context/DocumentosContext'
import IaSvg from "../assets/IA.svg"

const StyledCard = styled(Card)`
`

export interface PetitionPreviewInterface {
  id: string;
  dataDaUltimaAtualizacao: string;
  tipo: string;
  tipoDeProcesso: string;
  periodo: string;
  ia_tags?: string[];
  ia_decisoes?: string[];
  ia_leis?: string[];
  ia_resumo_seo?: string;
  ia_topicos?: string[];
  ia_resumo?: string;
  ia_meta?: {
    id: string;
    score?: number | null;
  };
  titulo: string;
  slug: string;
  tipoDeAcao: string;
  dataDeCriacao: string;
  tiposDeBeneficio: string[];
  subtiposDeBeneficio: string[];
  resumo: string;
  sexo: "FEMININO" | "MASCULINO";
  status: "REVISANDO" | "FINALIZADO" | "PENDENTE"; // Adaptação para possíveis status
  _meta?: {
    id: string;
    score?: number | null;
  };
}

const MAX_CHAR_LIMIT = 300;

export interface PetitionProps {
  petitions: PetitionPreviewInterface[]
  editorRedirect: (id: string) => void
  loading: boolean
  setOpen: any
  setSelectedId: any
}

export const PetitionPreview = (props: PetitionProps): JSX.Element => {
  const { petitions, loading } = props
  const { theme, darkMode } = usePrevTheme()
  const { user: { checkPaywall }, search: { setSearchQuery, setQuery } } = useContext(DocumentosContext)
  const [expandedPetitions, setExpandedPetitions] = useState<Set<string>>(new Set());

  const toggleExpand = (id: string): void => {
    setExpandedPetitions((prev) => {
      const updated = new Set(prev);
      if (updated.has(id)) {
        updated.delete(id);
      } else {
        updated.add(id);
      }
      return updated;
    });
  };

  const getDisplayText = (text: string, isExpanded: boolean): string => {
    if (isExpanded) return text;
    return text.length > MAX_CHAR_LIMIT ? text.slice(0, MAX_CHAR_LIMIT) + '...' : text;
  };

  const formatDateTime = (date): string => {
    const dateString = new Date(date).toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    const timeString = new Date(date).toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit'
    })

    return `${dateString} - ${timeString}`
  }

  const renderSkeleton = (): JSX.Element | null => {
    if (loading) {
      return (
        <Row>
          <Row
            data-testid='card-peticao'
            aria-label=''
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: "16px",
              gap: '10px',
            }}
          >
            <Skeleton data-testid='skeleton-peticao' loading={loading} active />
          </Row>
          <Row
            data-testid='skeleton-card-loading'
            aria-label=''
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              marginBottom: "16px"
            }}
          >
            <Skeleton data-testid='skeleton-peticao' loading={loading} active />
          </Row>
          <Row
            data-testid='skeleton-card-loading'
            aria-label=''
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              marginBottom: "16px"
            }}
          >
            <Skeleton data-testid='skeleton-peticao' loading={loading} active />
          </Row>
        </Row>
      )
    }
    return null
  }

  return (
    <PrevPlatformProvider>
      <Row style={{ gap: '15px' }}>
        {petitions?.length >= 1 &&
          petitions.map((petition, index) => {
            const isExpanded = expandedPetitions.has(petition.id);
            return !loading ? (
              <>
                <StyledCard
                  aria-label=''
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                  }}
                  data-testid={`card-resumo-peticao-${petition.titulo}`}
                  key={petition.resumo}
                >
                  <Row justify="space-between" style={{ marginBottom: "8px" }}>
                    <Row style={{ gap: '20px' }}>
                      {petition.tiposDeBeneficio.slice(0, 1).map((beneficio) => (
                        beneficio.length ? (
                          <Tag
                            data-testid={`peticao-resumo-tag-${beneficio}`}
                            key={`tag-${petition.id}`}
                            style={{
                              border: "none",
                              backgroundColor:
                                theme.color.background.accent.blue.subtler.default,
                              color: theme.color.text.accent.blue.default,
                              padding: '0 8px',
                              borderRadius: 128,
                              fontSize: "16px"
                            }}
                          >
                            {
                              BENEFICIOS.find(
                                (beneficioConst) => beneficio == beneficioConst.value
                              )?.label
                            }
                          </Tag>
                        ) : null
                      ))}
                    </Row>
                    <Col>
                      {petition._meta?.score && (
                        <Row style={{ gap: "5px" }}>
                          <StarFilled
                            style={{
                              color: theme.color.text.brand
                            }}
                          />
                          <Text emphasis><Text emphasis>{((petition._meta?.score / 2) / 100).toFixed(1)}</Text></Text>
                        </Row>
                      )
                      }
                    </Col>
                  </Row>
                  <Row style={{ gap: '20px' }} justify='space-between'>
                    {petition?.subtiposDeBeneficio?.map((beneficio) => (
                      <Tag
                        key={`subtipo-${petition.id}`}
                        data-testid={`peticao-resumo-subtipo-${beneficio}`}
                        style={{
                          backgroundColor: theme.color.background.accent.blue.bolder.default,
                          color: theme.color.text.inverse,
                          padding: '0 8px',
                          borderRadius: 128,
                          border: "none",
                          fontSize: "14px"
                        }}
                      >
                        {
                          SUBTIPOS.find(
                            (beneficioConst) => beneficio === beneficioConst.value
                          )?.label
                        }
                      </Tag>
                    ))}
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          alignSelf: 'flex-start',
                          gap: '30px',
                          alignItems: "center"
                        }}
                      >
                        <Text color={theme.color.text.subtlest} data-testid='peticao-resumo-data-publicacao' style={{
                          backgroundColor: theme.color.background.disabled,
                          color: theme.color.text.subtle,
                          padding: '0 8px',
                          borderRadius: 128,
                          border: "none",
                          fontSize: "14px"
                        }}>
                          Publicado: {formatDateTime(petition.dataDeCriacao)}
                        </Text>
                        <Text color={theme.color.text.subtlest} data-testid='peticao-resumo-data-atualizacao' style={{
                          backgroundColor: theme.color.background.disabled,
                          color: theme.color.text.subtle,
                          padding: '0 8px',
                          borderRadius: 128,
                          border: "none",
                          fontSize: "14px"
                        }}>
                          Atualizado:{' '}
                          {formatDateTime(petition.dataDaUltimaAtualizacao)}
                        </Text>
                      </div>
                    </Col>
                  </Row>
                  <Text emphasis style={{ marginTop: '10px' }} data-testid='peticao-resumo-titulo'>
                    {petition.titulo}
                  </Text>
                  <Text data-testid='peticao-resumo-texto'>{getDisplayText(petition.resumo, isExpanded)}</Text>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '16px'
                    }}
                    align='middle'
                    gutter={12}
                  >
                    <Col>
                      {petition.resumo.length > MAX_CHAR_LIMIT && (
                        <Button
                          type="link"
                          style={{ color: theme.color.text.info, padding: 0 }}
                          onClick={() => toggleExpand(petition.id)}
                          icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
                          iconPosition="end"
                        >
                          {isExpanded ? 'Ver menos' : 'Ver mais'}
                        </Button>
                      )}
                    </Col>
                    <Col>
                      <Row style={{ display: 'flex', gap: '30px', }} align='middle'>
                        <Button decoration={true} data-testid='btn-visualizar-peticao' icon={<EyeOutlined />} type='link' style={{ color: !darkMode ? theme.color.text.brand : 'white', padding: 0 }} onClick={() => {
                          props.setOpen(true)
                          props.setSelectedId(petition.id)
                        }}>
                          Pré-visualizar petição
                        </Button>
                        <Button data-testid='btn-abrir-editor' decoration={true} icon={<EditOutlined />} type='link' style={{ color: !darkMode ? theme.color.text.brand : 'white', padding: 0 }} onClick={() => {
                          const ok = checkPaywall()
                          if (ok) {
                            props.editorRedirect(petition.id)
                          }
                        }}>
                          Abrir no editor
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </StyledCard>
                {
                  index === 0 && petition?.ia_topicos && petition?.ia_topicos?.length > 0 && (
                    <Row >
                      <StyledCard aria-label='' data-testid='card-sugestoes-ia'>
                        <Row style={{ columnGap: "8px", marginBottom: "8px" }}>
                          <img src={IaSvg} style={{ fontSize: "12px" }} />
                          <Text emphasis>Tópicos sugeridos pela IA</Text>
                        </Row>
                        <Row style={{ columnGap: "12px" }}>
                          {
                            petition.ia_topicos?.map((e, indexTopico) => (
                              <Text
                                onClick={() => {
                                  setQuery(e)
                                  setSearchQuery(e)
                                }}
                                style={{ cursor: "pointer", color: theme.color.text.info }} emphasis>
                                {e}
                              </Text>
                            ))
                          }
                        </Row>
                      </StyledCard>
                    </Row>
                  )
                }
              </>
            ) : (
              <Row key={petition.id} style={{ width: "100%" }}>
                <Row
                  data-testid='card-peticao'
                  aria-label=''
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: "16px",
                    gap: '10px',
                  }}
                >
                  <Skeleton data-testid='skeleton-peticao' loading={true} active />
                </Row>
                <Row
                  data-testid='skeleton-card-loading'
                  aria-label=''
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    marginBottom: "16px"
                  }}
                >
                  <Skeleton data-testid='skeleton-peticao' loading={true} active />
                </Row>
                <Row
                  data-testid='skeleton-card-loading'
                  aria-label=''
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    marginBottom: "16px"
                  }}
                >
                  <Skeleton data-testid='skeleton-peticao' loading={true} active />
                </Row>
              </Row>
            )
          })}
      </Row>
      {renderSkeleton()}
      {!loading && (!petitions || petitions.length === 0) && (
        <Empty
          data-testid='empty-peticao'
          description="Não foram encontradas petições para este filtro"
          style={{ marginTop: '100px' }}
        />
      )}
    </PrevPlatformProvider>
  )
}
