import { PrevPlatformProvider } from '@prev/ui-kit'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import React, { Suspense } from 'react'
import { AnimationHandlerProvider } from './context/AnimationHandlerContext'
import { UseSearchInterface } from '@prev-front/mf-template'
import { Laudos } from './pages/Laudos'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const Petitions = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-petitions" */ './pages/Petitions'
    ).then(module => ({ default: module.Petitions }))
)

const PetitionView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-petitionview" */ './pages/PetitionView'
    ).then(module => ({ default: module.PetitionView }))
)

const EditorView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-editorview" */ './pages/EditorView'
    ).then(module => ({ default: module.EditorView }))
)

const Jurisprudencia = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-jurisprudencia" */ './pages/Jurisprudencia'
    ).then(module => ({ default: module.Jurisprudencia }))
)

const JurisprudenceView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-jurisprudenceView" */ './pages/JurisprudenceView'
    ).then(module => ({ default: module.JurisprudenceView }))
)

const Casos = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-casos" */ './pages/Casos'
    ).then(module => ({ default: module.Casos }))
)

const ClientEditorView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-clientEditorView" */ './pages/ClientEditorView'
    ).then(module => ({ default: module.ClientEditorView }))
)

export interface RootProps {
  context: UseSearchInterface & { scrollTop: () => void, checkPaywall: () => boolean }
}

export default function Root(props: RootProps): JSX.Element {
  const darkMode = localStorage.getItem('darkMode')
  if (darkMode === 'true') {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#27272E'
  } else {
    document.getElementsByTagName('body')[0].style.backgroundColor = 'white'
  }

  return (
    <PrevPlatformProvider>
        <Suspense>
          <BrowserRouter>
            <Routes>
              <Route path="/peticoes" Component={() =>
                <AnimationHandlerProvider>
                  <Petitions searchContext={props.context} />
                </AnimationHandlerProvider>
              }
              />
              <Route path="/peticoes/view/:id" Component={() => <PetitionView />} />
              <Route path="/peticoes/editor/:id" Component={() => <AnimationHandlerProvider><EditorView /></AnimationHandlerProvider>} />
              <Route path="/peticoes/editor/:id/:cpf" Component={() => <ClientEditorView />} />
              <Route path="/jurisprudencia" Component={() => <Jurisprudencia searchContext={props.context} />}
              />
              <Route path="/jurisprudencia/view/:id" Component={() => <GoogleReCaptchaProvider reCaptchaKey="6Lf0VccUAAAAANdGE1BkdpnSEnbb07RmUKLIP3KK" language="pt-BR"><JurisprudenceView /></GoogleReCaptchaProvider>} />
              <Route path="/casos" Component={() =>
                <AnimationHandlerProvider searchContext={props.context}>
                  <Casos />
                </AnimationHandlerProvider>
              }
              />
              <Route path="/laudos" Component={() =>
                <GoogleReCaptchaProvider reCaptchaKey="6Lf0VccUAAAAANdGE1BkdpnSEnbb07RmUKLIP3KK" language="pt-BR">
                  <AnimationHandlerProvider searchContext={props.context}>
                    <Laudos searchContext={props.context} />
                  </AnimationHandlerProvider>
                </GoogleReCaptchaProvider>
              }
              />
            </Routes>
          </BrowserRouter>
      </Suspense>
    </PrevPlatformProvider>
  )
}
